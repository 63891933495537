<template>

    <div id="wrapper">

      <div class="mx-auto my-12 px-8 lg:px-24 pb-40">

        <router-link :to="{name: 'Home'}">
          <nag-button button-text="Back to Landing" icon-name="arrow-back" icon-position="left" />
        </router-link>
        
          <div class="outer mt-8">
            <h1 class="text-6xl font-serif italic font-bold -rotate-12  origin-top block absolute">{{ page_content.page_title }}</h1>
          </div>
        
          <div class="flex flex-col lg:w-3/4 gap-10">

            <prismic-rich-text class="prose max-w-none prose-headings:font-serif w-full self-center" :field="page_content.content " />

            <div v-if="page_content.artwork_image">
              <img :src="page_content.artwork_image.url" class="lg:w-3/4 border mb-3" :alt="page_content.artwork_image.alt" />
              <p class="text-xs">{{page_content.artwork_note}}</p>
            </div>

            <div class="flex gap-10">
              <prismic-rich-text class="prose max-w-none prose-headings:font-serif" :field="page_content.signature" />
            </div>

        </div>

      </div>

      <Footer />

   </div>

</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
	name: "Story",
  data(){
    return {
      page_content: null
    }
  },
  components: {
    Footer
  },
  async mounted(){
    this.$emit('hideMenu')
		const response = await this.$prismic.client.getSingle('story')
    console.log(response)
    this.page_content = response.data
  }
}
</script>

<style>
  .h-screen-half {
    height: 50vh;
  }
  .outer {
      height: 12rem;
  }
  #wrapper:before {
    content: " ";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100vh;  
    opacity: .5; 
    z-index: -1;
    overflow: hidden;
    background-image: url('../assets/contour-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
</style>
